import React, { useEffect, useContext } from 'react';
import { WebSocketContext } from "../WebSocketContext";


function BottomTime() {
    const { lastUpdateTime } = useContext(WebSocketContext);

  return (
    <>
    {  <p style={{backgroundColor:'black', color:'white', padding:'2px', fontSize:'10px', textAlign:'center', marginBottom:'-0.5px'}} className="lastUpdate">Updated @ {lastUpdateTime ? lastUpdateTime.toLocaleString() : "N/A"}</p>}
    </>
  );
}

export default BottomTime;

